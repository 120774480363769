
<script setup>
import api from '@/assets/js/api_router.js'
//import { authHeader } from '@/assets/js/auth.js'
import { useStore } from 'vuex'
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router'
import DeploymentMap from '@/components/DeploymentMap.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

console.log(api)

const deployments = ref([])
const recognizers = ref([])
const intervals = ['day', 'week', 'month', 'year']
const selected_interval = ref('week')

const selected_deployment_name = ref('')
const selected_recognizer_name = ref('')

const selected_deployment = ref(-1)
const selected_recognizer = ref(-1)



onMounted(() => {
     
    fetch(api('deployments'), { headers: store.getters.authHeader() })
      .then(res => res.json())
      .then(data => {
        deployments.value = data
        // update the selected deployment index now that we know what the deployments are
        selected_deployment.value = deployments.value.findIndex(
          d => d.id == route.params.deployment_id
        )
        selected_deployment_name.value =
          selected_deployment.value > -1
            ? deployments.value[selected_deployment.value].name
            : 'Select Deployment'
      })
      .catch(error => console.log(error.message))

    fetch(api('recognizers'), store.getters.authHeader())
      .then(res => res.json())
      .then(data => {
        recognizers.value = data
        selected_recognizer.value = recognizers.value.findIndex(
          r => r.id == route.params.recognizer_id
        )
        selected_recognizer_name.value =
          selected_recognizer.value > -1
            ? recognizers.value[selected_recognizer.value].name
            : 'Select Recognizer'
      })
      .catch(error => console.log(error.message))

    // update the selected label based on the route param
    if (route.params.interval) selected_interval.value = route.params.interval;
  })

const selectBoth = (i, recognizer_i=-1) => {
  selectDeployment(i)
  if (recognizer_i > -1) selectRecognizer(recognizer_i)


}

const selectDeployment = (i) => {
      console.log(i)
      selected_deployment.value = i
      selected_deployment_name.value =
        deployments.value[selected_deployment.value].name
      updateRoute()
    }

const selectRecognizer = (i) => {
      selected_recognizer.value = i
      selected_recognizer_name.value = recognizers.value[i].name
      updateRoute()
    }

const selectInterval = (interval) => {
      selected_interval.value = interval
      console.log(selected_interval.value)
      updateRoute()
    }

const updateRoute = () => {
      if (selected_deployment.value > -1 && selected_recognizer.value > -1) {
        router.push({
          name: 'detections',
          params: {
            deployment_id: deployments.value[selected_deployment.value].id,
            recognizer_id: recognizers.value[selected_recognizer.value].id,
            interval: selected_interval.value
          }
        })
      }
    }



//const deploymentSelected = (id) => console.log('deployment selected', id)


</script>


<template>

<deployment-map :pins="deployments" @pin-clicked="(i, recognizer_id) => selectBoth(i, recognizer_id)" />

<div class="deployment_list_wrapper">
  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ selected_deployment_name }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-for="(item, i) in deployments" :key="i">
        <a @click="selectDeployment(i)" class="dropdown-item" :class='{active: selected_deployment == i}'>{{
          item.name
        }}</a>
      </li>
    </ul>
  </div>

  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ selected_recognizer_name }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-for="(item, i) in recognizers" :key="i">
        <a @click="selectRecognizer(i)" class="dropdown-item">{{
          item.name
        }}</a>
      </li>
    </ul>
  </div>

  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      by {{ selected_interval }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-for="(item, i) in intervals" :key="i">
        <a @click="selectInterval(item)" class="dropdown-item">by {{ item }}</a>
      </li>
    </ul>
  </div>
  </div>



</template>

<style lang='scss' scoped>

.deployment_list_wrapper {
   margin-top: 20px;
  display: flex;
  justify-content: center;
}

.dropdown {
  margin-right: 10px;
}



</style>