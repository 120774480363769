import { getColorsAuto, mapLabel, styleRgb } from '@/assets/js/chart_utils.js'


function mapRawData(data) {

    return data.map(d => {
        // this will update the row because the object is passed by reference
        var rate = 3600 * d.count / d.seconds_in_period
        d.detection_rate_per_hour = Math.round(rate * 100) / 100;
        return (d);
    })

}


//https://plotly.com/javascript/time-series/
function chart(raw_data_selection, raw_data_all, interval, use_bar) {

    console.log(raw_data_selection);

    let data = mapRawData(raw_data_selection)

    let data_all = mapRawData(raw_data_all)
    data_all.forEach(row => row.predicted_class = row.predicted_class)


    var dates = data.map(row => row.period_start)
    var min_date = dates.reduce((prev, cur) => prev < cur ? prev : cur)
    var max_date = dates.reduce((prev, cur) => prev > cur ? prev : cur)

    // remove dates outside of what we want
    data_all = data_all.filter(d => d.period_start >= min_date && d.period_start <= max_date)


    data = longToWide(data, 'predicted_class', 'detection_rate_per_hour');
    data_all = longToWide(data_all, 'predicted_class', 'detection_rate_per_hour');

    var species = Object.keys(data).sort()
    var colors = getColorsAuto(species.length)
    var lines = []
    const type = use_bar ? 'bar' : 'scatter'

    species.forEach((species_name, i) => {

        lines.push({
            type: type,
            name: mapLabel(species_name),
            legendgroup: species_name,
            x: data[species_name].map(val => val.period_start),
            y: data[species_name].map(val => val.detection_rate_per_hour),
            marker: { color: styleRgb(colors[i]) },
            line: { shape: 'spline' },
            mode: 'lines+markers'
        })

        lines.push({
            type: type,
            name: mapLabel(species_name) + " (region)",
            legendgroup: species_name,
            showlegend: false,
            x: data_all[species_name].map(val => val.period_start),
            y: data_all[species_name].map(val => val.detection_rate_per_hour),
            marker: {
                color: styleRgb(colors[i], 0.5),
                pattern: {
                    shape: "-",
                },

            },
            line: {
                dash: 'dot',
                shape: 'spline'
            }
        })

    })



    var layout = {
        //title: 'Average calls detected per hour',
        xaxis: {
            autorange: true,
            range: [min_date, max_date],
            rangeselector: {
                buttons: [

                ]
            },
            rangeslider: { range: [min_date, max_date] },
            type: 'date'
        },
        yaxis: {
            autorange: true,
            rangemode: 'tozero',
            range: [0, 15 * 60],
            type: 'linear'
        }
    };

    Plotly.newPlot('chart', lines, layout);

}

function longToWide(long_data, col1, col2) {
    // this will edit the object inside long data removing col. To preserve it we need to clone the object

    let wide_data = {}

    long_data.forEach(el => {
        let new_key = el[col1]
        // the 'count' property name needs to be the name of the variable
        el[new_key] = el[col2]
        if (!Object.prototype.hasOwnProperty.call(wide_data, new_key)) {
            wide_data[new_key] = [];
        }
        delete el[col1]
        wide_data[new_key].push(el);
    });

    return wide_data;
}


// function fillMissingData(data) {






// }

// function getDateSeries(min_str, max_str, interval) {
//     /**
//      * returns an array of date (or datetime if interval is hour), between min and max
//      */
//     var date_array = new Array()
//     var current_date = new Date(min_str)
//     var max = new Date(max_str)
//     var end_char = (interval == 'hour') ? 19 : 10
//     while (current_date <= max) {
//         date_array.push(current_date.toISOString().slice(0, end_char));
//         switch (interval) {
//             case 'month':
//                 // code block
//                 break;
//             case 'week':
//                 // code block
//                 break;
//             case 'week':
//                 // code block
//                 break;
//             case 'day':
//                 // code block
//                 break;

//         }
//         // current_date = new Date(current_date.getTime() + );
//     }

//     return (date_array)

// }


// export default function loadChart(url, interval) {
//     console.log('load chart');
//     console.log(url);

//     fetch(url)
//         .then(response => response.json())
//         .then(data => chart(data, interval))
//         .catch(error => {
//             console.error('There has been a problem with your fetch operation:', error);
//         });

//     // the timeseries chart we chose uses an old version of d3, but newer ones would be with promise
//     //d3.json(url).then(chart);

// }

export default chart