<script setup>

import PageHeader from '@/components/PageHeader.vue'
import { useRoute } from 'vue-router'

const route = useRoute()

console.log('process.env.VUE_APP_API_ROOT', process.env.VUE_APP_API_ROOT)
console.log('process.env.VUE_APP_BING_MAPS_API_KEY', process.env.VUE_APP_BING_MAPS_API_KEY)


</script>

<template>
  <page-header class="main_header" v-if="route.path != '/'" />
  <router-view />
</template>

<style lang="scss">
@import '@/assets/scss/variables.scss';


html {
  overflow-y: scroll;
}

body {
  background-color: $color0 !important;
  color: $color1;
}
body.home {
  background: $color5 !important;
}


#app {
  font-family: 'Quicksand', 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $color1;
  padding-top: 120px;
}

.home #app {
  padding-top: 0px;
}

h1, h2, h3, .serif {
  font-family: Merriweather;
}

header {

  color: $color5l;
  background-color: $color4;
 
  border-bottom: 5px solid $color5l;

}

div a {
  color: $color4;
}
div a:hover {
  color: $color1;
}





</style>
