
<script setup>
import { ref } from 'vue'
import api from '@/assets/js/api_router.js'
//import { setUser } from '@/assets/js/auth.js'
import VueJwtDecode from "vue-jwt-decode";
import { useStore } from 'vuex'
//import { useRouter } from 'vue-router'

//const router = useRouter()


const store = useStore()

const form = ref({
  username: '',
  password: ''
})

const onSubmit = async () => {
  console.log('submit form!')
  try {
    const response = await fetch(api('login'),
      {
        method: "POST",
        //mode: "no-cors",
        headers: { 'Content-Type': "application/json" },
        // text plain to deal the the no-cors mode https://stackoverflow.com/questions/37668282/unable-to-fetch-post-without-no-cors-in-header
        //headers: {'Content-Type': "text/plain"},
        //body: JSON.stringify({username: 'phil', password: '123'})
        body: JSON.stringify(form.value)
      })
      .then(response => {
        console.log('login response received', response, response.headers)
        // let token = response.data.data.token;
        // localStorage.setItem("user", token);
        // // navigate to a protected resource 
        // this.$router.push("/me");
        return response.json()
      })
      .then(result => {
        //https://blog.logrocket.com/how-to-implement-jwt-authentication-vue-nodejs/
        console.log('Success:', result);
        console.log(result.access_token)
        const user = {
          username: result.username,
          id: result.id,
          access_token: result.access_token
        }

        let decoded = VueJwtDecode.decode(result.access_token)
        console.log(decoded)

        store.dispatch('login', user)

        // router.push({
        //   name: 'detections_landing'
        // })

      })
      .catch(error => {
        console.log('something went wrong with login', error)
      })

    console.log('response ! ! !', response, response.body)

  } catch (err) {
    console.log(err.response);
  }
}

</script>

<template>

  <div class="welcome">Log in to find out what's on your farm</div>

  <div class="login_form">
    <form @submit.prevent="onSubmit">
      <input v-model="form.username" placeholder="username" />
      <input type="password" v-model="form.password" placeholder="password" />
      <input type="submit" value="login" class="btn" />
    </form>
  </div>


</template>


<style lang='scss' scoped>
@import '@/assets/scss/variables.scss';

.welcome {
  margin: 170px 100px 20px 100px;
  text-align: center;
}

.login_form {
  margin: 20px 20px 20px 20px;
  display: flex;

  form {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    text-align: left;
    display: block;
    margin-top: 10px;
    font-size: 1.1em;
    padding: 0.3em 0.5em 0.3em 0.5em;
    border: 1px solid $color4;
    border-radius: 5px;
  }

  input.btn {
    border: 1px solid $color4;
    background: $color5l;
  }

  input.btn:hover {
    border: 1px solid $color4;
    background: $color5;
  }
}
</style>