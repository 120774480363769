
const getColorsAuto = (num_colors) => {
    /**
     * Returns an array of colors (as RGB arrays) that are equally spaced hue angles apart
     */

    var angle = 360 / num_colors;
    var sat = [0.4, 0.6]
    var lum = [0.6, 0.4]

    return [...Array(num_colors).keys()].map(i => hsl2rgb(i * angle, sat[i % 2], lum[i % 2]));

}

const mapLabel = (label) => {
    const map = {
        'southern_boobook': 'australian_boobook',
        'cisticola': 'Golden-headed Cisticola',
        'ChalGoul alt': 'Chalinolobus gouldii (alternating)',
        'Mormopterus planiceps': 'Ozimops planiceps ',
        'Mormopterus rp': 'Ozimops ridei'
    }
    if (label in map) label = map[label]
    return label.split("_").map(w => w[0].toUpperCase() + w.substr(1)).join(" ")

}


// input: h in [0,360] and s,v in [0,1] - output: r,g,b in [0,1]
function hsl2rgb(h, s, l) {
    let a = s * Math.min(l, 1 - l);
    let f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    //return `rgb(${f(0) * 255},${f(8) * 255},${f(4) * 255})`
    // return an array of values
    return [f(0), f(8), f(4)].map(c => Math.round(c * 255));
}

function styleRgb(rgb, alpha = 1) {
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`
}

export { getColorsAuto, mapLabel, styleRgb }