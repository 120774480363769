
<script setup>
import DeploymentList from '@/components/DeploymentList.vue'

import chart from '@/assets/js/chart2.js'
import barchartRace from '@/assets/js/barchart_race.js'
//import t from '@/assets/js/mytest.js';
import { ref, computed, onMounted, watch } from "vue";
import api from '@/assets/js/api_router.js'
import { useRoute } from 'vue-router'
//import { authHeader } from '@/assets/js/auth.js'
import { useStore } from 'vuex'

const store = useStore()

const route = useRoute();


const urls = computed(() => {
  // returns a url for the 'selection' which is the deployment_id(s) selected
  // and the 'all' which is average detection rates across all locations
  return { 
    'selection': api('detections', {
          deployment_id: route.params.deployment_id,
          recognizer_id: route.params.recognizer_id,
          interval: route.params.interval
        }), 
    'all': api('detections', {
          deployment_id: 'all',
          recognizer_id: route.params.recognizer_id,
          interval: route.params.interval
        })
  }
})


const message = ref('')

const detection_data = ref({
  selection: [],
  all:[]
})

const use_bar = ref(false)

const last_data_change = ref(new Date())
watch(last_data_change, () => {
  
  if(detection_data.value.selection.length > 0 && detection_data.value.all.length > 0) {
    console.log(barchartRace)

    barchartRace(structuredClone(detection_data.value.selection), route.params.interval);

    const num_periods = (new Set(detection_data.value.selection.map(row => row.period_start))).size

    // bar chart looks nicer for few x axis items, lines look better for more
    use_bar.value = num_periods < 4

    chart(
      structuredClone(detection_data.value.selection), 
      structuredClone(detection_data.value.all), 
      route.params.interval, 
      use_bar.value)
  }
})

const has_data = computed(() => detection_data.value.selection.length > 0)

onMounted(() => {

  // watch the params of the route to fetch the data again
  // why is this inside onMounted?
  watch(
    () => route.params,
    () => {

      if (urls.value.selection) {
        const selection_data = store.getters.data(urls.value.selection)
      
        if (selection_data == false) {
          detection_data.value.selection = []
          fetch(urls.value.selection, { headers: store.getters.authHeader() })
            .then(response => response.json())
            .then(data => {
              detection_data.value.selection = data
              store.commit('setData', { url: urls.value.selection, value: data})
              last_data_change.value = new Date()
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation (selection):', error);
            });
        } else {
          detection_data.value.selection = selection_data
          last_data_change.value = new Date()
        }

        const data_all = store.getters.data(urls.value.all)
        if (data_all == false) {
          detection_data.value.all = []
          fetch(urls.value.all, { headers: store.getters.authHeader() })
            .then(response => response.json())
            .then(data => {
              detection_data.value.all = data
              store.commit('setData', { url: urls.value.all, value: data})
              last_data_change.value = new Date()
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation (all):', error);
            });
        } else {
          detection_data.value.all = data_all
          last_data_change.value = new Date()
        }
      }
    },
    // fetch the data when the view is created and the data is
    // already being observed
    { immediate: true }
  )
  
})
</script>


<template>

  <div class="chart_view_wrapper">
    <deployment-list />
    <div :class="{hidden: !has_data}">
      <h3 class="plot_heading"> Average {{ route.params.recognizer_id == 1 ? 'calls' : 'passes'  }} detected per hour (dotted {{ use_bar ? 'bar' : 'line' }} is the average for the region) </h3>
      <div id="chart"></div>

      <h3 class="plot_heading"> Total calls detected </h3>
      <div id="barchart_race"></div>

    </div>

    <div v-if="message">
      {{message}}
    </div>


  </div>

</template>


<style lang='scss'>

@import "@/assets/scss/variables.scss";

.hidden {
  opacity: 0;
}

h3.plot_heading {
  font-size: 1.3em;
  color: $color4;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 20px;
}

.chart_view_wrapper {
 
  text-align: center;
}

#chart {
  height: calc(100vh - 200px);
  min-height: 500px;
}


// barchart race
#barchart_race {
  margin: 0 55px 0 55px;
}

text{
font-size: 16px;
font-family: Open Sans, sans-serif;
}
text.title{
font-size: 24px;
font-weight: 500;
}
text.subTitle{
  font-weight: 500;
  fill: #777777;
}
text.caption{
  font-weight: 400;
  font-size: 14px;
  fill: #777777;
}
text.label{
  font-weight: 600;
}

text.valueLabel{
  font-weight: 300;
}

text.yearText{
  font-size: 64px;
  font-weight: 700;
  opacity: 0.25;
}
.tick text {
  fill: #777777;
}
.xAxis .tick:nth-child(2) text {
  text-anchor: start;
}
.tick line {
  shape-rendering: CrispEdges;
  stroke: #dddddd;
}
.tick line.origin{
  stroke: #aaaaaa;
}
path.domain{
  display: none;
}

</style>