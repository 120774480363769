<script setup>
//import { computed } from 'vue'
//import { useRoute } from 'vue-router'
//import { getUser } from '@/assets/js/auth.js'
import LogIn from '@/components/LogIn.vue'
import { useStore } from 'vuex'


const store = useStore()


//const user = computed(() => store.getUser())

</script>




<template>


  <template v-if="!store.getters.user">
     
     <LogIn />
  
  </template>

 

</template>



<style lang="scss" scoped>




</style>
