<script setup>

import DetectionChart from '@/components/DetectionChart.vue'

import LogIn from '@/components/LogIn.vue'
import { useStore } from 'vuex'

const store = useStore()


</script>


<template>
  <div class="chart">

    <detection-chart v-if="store.getters.user" />
    <log-in v-if="!store.getters.user" />
  </div>
</template>


