import { createStore } from 'vuex'

// we expire the cached data every 5 mins
// this is just so when you are clicking about a lot exploring and visit the same
// api url twice, you don't make another request
const ttl_ms = 1000 * 60 * 5

const state = () => {

    let user = localStorage.getItem('user')
    if (user) {
        user = JSON.parse(user)
    }

    return {
        user: user,
        data: {}
    }
}

const mutations = {
    setUser: (state, user) => {
        state.user = user;
    },
    setData: (state, { url, value }) => {
        state.data[url] = {
            expires: new Date((new Date()).getTime() + ttl_ms),
            value: value
        }
    }
}

const getters = {
    user: (state) => {
        return state.user;
    },
    authHeader: (state) => (header = {}) => {
        // return authorization header with jwt token
        if (state.user && state.user.access_token) {
            header['Authorization'] = 'Bearer ' + state.user.access_token;
        }
        //console.log('header', header)
        return header
    },
    data: (state) => (url) => {
        if (url in state.data) {
            if (state.data[url].expires > new Date()) {
                return state.data[url].value
            } else {
                // value has expired
                delete state.data[url]
                return false
            }
        }
        return false
    }
}

const actions = {
    login: ({ commit }, user) => {
        localStorage.setItem('user', JSON.stringify(user))
        commit('setUser', user)

    },
    logout: ({ commit }) => {
        localStorage.removeItem('user')
        commit('setUser', null)
    }
}



// Create a new store instance.
const store = createStore({
    state,
    mutations,
    actions,
    getters
})

export default store