<script setup>
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
const route = useRoute()
const router = useRouter()
console.log(router)

const titles = {
  home: '',
  login: 'Log In',
  detections_landing: 'Bird and Bat Detections',
  detections: 'Bird and Bat Detections',
  bird_species_info: 'Bird Species',
  bat_species_info: 'Bat Species'
}

const heading = computed(() => {
  console.log('route name', route.name)
  return titles[route.name]
})
</script>

<template>
  <h1>{{ heading }}</h1>
</template>