
<script setup>
import { computed } from 'vue'
//import { getUser, setUser } from '@/assets/js/auth.js'
import { useStore } from 'vuex'

const store = useStore()

const user = computed(() => store.getters.user)

</script>

<template>

    <nav class="nav_menu" id="nav_menu">
      <ul>
        <li><router-link to="/detections">Detections</router-link></li>
        <li><router-link to="/species/birds">Bird Species</router-link></li>
        <li><router-link to="/species/bats">Bat Species</router-link></li>
        <li v-if="user"><a @click.prevent="store.dispatch('logout')" href="/logout">logout {{user.username }}</a></li>
        <li v-if="!user"><router-link to="/login">Login</router-link></li>
      </ul>
    </nav> 

</template>


<style lang='scss' scoped>

@import '@/assets/scss/variables.scss';


  a {
    font-weight: bold;
    color: $color5l;
    text-decoration: none;
    font-size: 1.1em;
  }

  a:hover {
    color: $color0;
      text-decoration: underline;
  }

  a.router-link-exact-active {
    color: $color0;
  }


</style>