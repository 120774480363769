
<script setup>
import NavItems from '@/components/NavItems.vue'
import PageHeading from '@/components/PageHeading.vue'
import { onMounted } from 'vue'

onMounted(() => {
  window.addEventListener('scroll', function() {
     document.querySelector('header').classList.toggle("little", document.documentElement.scrollTop > 20 || document.body.scrollTop > 20 ); 
  });
  document.querySelector('header').classList.toggle("little", document.documentElement.scrollTop > 20 || document.body.scrollTop > 20 ); 
})


const burgerClick = () => {
  //https://codepen.io/alvarotrigo/pen/oNGzoYd
  document.querySelector("#burger_menu_wrapper #menu-bar").classList.toggle("change");
  document.querySelector("#burger_menu_wrapper #nav_menu").classList.toggle("change");
  document.querySelector("#burger_menu_wrapper #menu-bg").classList.toggle("change-bg");
}

</script>

<template>

  <header>
 
    <div class="logo_area">
       <router-link class="logo" to='/'><img src='/img/logo.png' /></router-link>
    </div>
    <div class="heading_area">
      <page-heading />
    </div>
    <div class="nav_area">
          
              <!-- <nav :class="{header: route.path != '/'}"> -->
      <div id="normal_menu_wrapper">
        <nav-items />
      </div>

      <div id="burger_menu_wrapper">
        <div id="burger_menu">
          <div id="menu-bar"  @click="burgerClick">
            <div id="bar1" class="bar"></div>
            <div id="bar2" class="bar"></div>
            <div id="bar3" class="bar"></div>
          </div>
          <nav-items />
        </div>
        <div class="menu-bg" id="menu-bg"></div>
      </div>

    </div>
   
  </header>

</template>


<style lang='scss'>

@import '@/assets/scss/variables.scss';


$large_height: 120px;
$small_height: 55px;
$trans_duration: 0.5s;
$burger_threshold: 700px;
$burger_threshold_little: 778px;

header.main_header {

  z-index: 9999;

  width: 100%;
  height: $large_height;
  position: fixed;
  top:0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all $trans_duration ease;

  .logo_area {
    z-index: 10;
    // grid-area: logo;
    display: flex;
    align-items: center;
    img {
      height: calc($large_height - 20px);
      margin-left: 8px;
      transition: all $trans_duration ease;
      width: auto;
    }
  }

  .heading_area {
    z-index: 20;
    position: absolute;
    top: 10px;
    left: calc(25% + 100px);
    // stops it getting in the way of nav
    pointer-events: none;
    
    // grid-area: heading;
    h1 {
      margin-top: 10px;
      font-size: 1.8em;
      transition: all $trans_duration ease;
    }
  }
  .nav_area {
    z-index: 5;
    position: relative;
    transition: all 0.5s ease;
    margin-top: 75px;
    // grid-area: nav;
    padding: 0px 15px 5px 10px;
  
  }

  &.little {

    height: $small_height;


    .heading_area {
      transition: all 0.5s ease;
      height: 0px;
      left: calc(15% + 5px);
    }

    .heading_area h1 {
      margin-top: 3px;
      font-size: 1.2em;
    }

    .nav_area {

      font-size: 0.9em;
      margin-top: 20px;
    }
     

    #burger_menu_wrapper #menu-bar {
      margin-top: 5px;

    }    
    #burger_menu_wrapper #burger_menu {
      top: -32px;
    }

    .logo_area {
      img {
        height: calc($small_height - 18px);
      }
    }

    transition: all 0.5s ease;
    // height: 60px;

  }

}


// ---- NAV --------


nav li {
  list-style: none;
}





#normal_menu_wrapper {

  li {
    display: inline;
    &::after {
      content: " | "
    }
    &:last-child::after {
      content: ""
    }
  }

  nav {
    text-align: right;
    word-spacing: 0.1em;
  }



}



// ------ burger menu -------

#burger_menu_wrapper {
  // to turn on or off the burger menu based on width, outside of whether the menu is open or closed

  position: relative;
  top: 0px;
  right: 0px;


  #burger_menu {
    position: absolute;
    top: -26px;
    right: 0px;
    z-index: 2;
    text-align: right;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 0px;
    height: 40px;

  }

  #menu-bar {
    width: 45px;
    height: 40px;   
    cursor: pointer;
    padding-top: 12px;
  }

  .bar {
    height: 5px;
    width: 100%;
    background-color: $color5l;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
  }

  #bar1 {
    transform: translateY(-4px);
  }

  #bar3 {
    transform: translateY(4px);
  }

  .nav_menu {
    transition: 0.3s ease;
    transform: translateX(200px);
  }

  .nav_menu ul {
    padding: 22px 22px 0px 0px;
  }

  .nav_menu li {
    padding: 12px 0;
  }

  .nav_menu li a {
    color: $color5l;
    font-size: 20px;
    text-decoration: none;
  }

  .nav_menu li a:hover {
    font-weight: bold;
  }

  .menu-bg, #menu {
    top: 0;
    left: 0;
    position: absolute;
  }

  .menu-bg {
    z-index: 1;
    width: 0;
    height: 0;
    margin: 30px 0 20px 20px;
    background: radial-gradient(circle, $color4, $color4);
    border-radius: 50%;
    transition: 0.3s ease;
  }

  .nav_menu.change {
    display: block;
    transform: translateX(0px);
  }

  

  .change #bar1 {
    transform: translateY(4px) rotateZ(-45deg);
  }

  .change #bar2 {
    opacity: 0;
  }

  .change #bar3 {
    transform: translateY(-6px) rotateZ(45deg);
  }

  .change-bg {
    width: 580px;
    height: 540px;
    transform: translate(-45%,-35%);
  }

}



@media only screen and (max-width: $burger_threshold) {
  #burger_menu_wrapper {
    display: block;
    opacity: 1;
    transition: all 0.5s ease;
    right: 0px;
  }

  #normal_menu_wrapper {
    display: none;
    right: -1000px;
    opacity: 0;
    transition: all 0.5s ease;
  }
}

@media only screen and (min-width: $burger_threshold) {
  #burger_menu_wrapper {
    display: none;
    opacity: 0;
    right: -1000px;
    transition: all 0.5s ease;

  }

  #normal_menu_wrapper {
    display: block;
    opacity: 1;
    transition: all 0.5s ease;
  }
}



@media only screen and (max-width: $burger_threshold_little) {
  .little #burger_menu_wrapper {
    display: block;
    opacity: 1;
    transition: all 0.5s ease;
    right: 0px;
  }

  .little #normal_menu_wrapper {
    display: none;
    right: -1000px;
    opacity: 0;
    transition: all 0.5s ease;
  }

  header.main_header.little  .heading_area {
    transition: all 0.5s ease;
    left: calc(25% + 5px);
  }


}



@media only screen and (min-width: $burger_threshold_little) {
  .little .little #burger_menu_wrapper {
    display: none;
    opacity: 0;
    right: -1000px;
    transition: all 0.5s ease;

  }

  .little #normal_menu_wrapper {
    display: block;
    opacity: 1;
    transition: all 0.5s ease;
  }
}





</style>