import config from '@/config.js'

const api_routes = {
    'deployments': 'deployments',
    'recognizers': 'recognizers',
    'detections': 'detections/:deployment_id/:recognizer_id/:interval',
    'login': 'login'
};

export default function (name, params = {}) {



    let url = config.apiBaseUrl + "/" + api_routes[name];
    for (const [key, value] of Object.entries(params)) {
        if (value === undefined || value == '') return false
        url = url.replace(':' + key, value);
    }

    // console.log('- - - - ')
    // console.log(config);
    // console.log(url);

    return url;

}

