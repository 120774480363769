import * as d3 from 'd3'
import { getColorsAuto, mapLabel, styleRgb } from '@/assets/js/chart_utils.js'

// config
//https://bl.ocks.org/jrzief/70f1f8a5d066a286da3a1e699823470f


var width = window.innerWidth - 180;
const margin = {
    top: 80,
    right: 0,
    bottom: 5,
    left: 0
};


let svg = null

const createElement = (num_bars) => {

    const px_per_bar = 60

    // append the svg
    d3.select("#barchart_race svg").remove();
    svg = d3.select("#barchart_race").append("svg")
        .attr("width", width)
        .attr("height", px_per_bar * num_bars + 60);

    // svg.append('text')
    //     .attr('class', 'title')
    //     .attr('y', 24)
    //     .html('18 years of Interbrand’s Top Global Brands');

    // svg.append("text")
    //     .attr("class", "subTitle")
    //     .attr("y", 55)
    //     .html("Brand value, $m");

    // let caption = svg.append('text')
    //     .attr('class', 'caption')
    //     .attr('x', width)
    //     .attr('y', height - 5)
    //     .style('text-anchor', 'end')
    //     .html('Source: Interbrand');

}

// sorted array of class labels
const getLabels = (data) => {
    return Array.from(new Set(data.map(d => d.predicted_class))).sort()
}

// either the first or last date in the dataset
const getEndDate = (data, start = true) => {
    return data.reduce((prev, cur) => {

        if (start) {
            return prev.date <= cur.date ? prev : cur
        } else {
            return prev.date > cur.date ? prev : cur
        }
    }).date;
}

/**
 * finds any data that falls on the given date
 * their counts for that date to the totals for each species
 * re-calculates the rankings
 * @param {array} data 
 * @param {Date} cur_date 
 * @param {array} totals 
 * @param {array} class_labels 
 * @returns 
 */
const updateTotals = (data, cur_date, totals, class_labels) => {

    // get the data that's in the current time step
    // TODO: it would be possibly slower for large sets but much more robust
    // to use a reduce to get sum up all the values less than cur_date. 
    // this means we don't have to assume that the binning falls exactly on our interval steps. 
    let curSlice = data.filter(d => d.date.getTime() == cur_date.getTime() && !isNaN(d.count))

    // add the values to the totals
    class_labels.forEach(l => {
        const totals_index = totals.findIndex(el => el.name == l)
        const slice_index = curSlice.findIndex(el => el.predicted_class == l)
        // important to do this for all, regardless of whether they have new values
        totals[totals_index].lastValue = totals[totals_index].value
        if (slice_index > -1) {
            // last value is used to interpolate the number label text 
            totals[totals_index].value += curSlice[slice_index].count
        }
    })

    // add a rank to each item for the slice
    totals = totals.sort((a, b) => b.value - a.value);
    totals.forEach((d, i) => d.rank = i);

    if (curSlice.length > 0) {
        console.log('new values found')
    }

    return totals;
}


/**
 * tick duration 
 */
const getTickDuration = (interval, min_date, max_date) => {

    //console.log(min_date, max_date)

    const tickDuration = {
        'hour': 100,
        'day': 500,
        'week': 2000,
        'month': 5000,
        'year': 10000
    }[interval]

    return tickDuration

}

const myMapLabel = (label) => mapLabel(label)


/**
 * 
 * @param {array} data 
 * @param {string} interval one of "day, week, month, year"
 */
const barchartRace = (data, interval) => {




    data.forEach(d => d.date = new Date(d.period_start))

    const class_labels = getLabels(data)
    let cur_date = getEndDate(data, true)
    const min_date = cur_date
    const max_date = getEndDate(data, false)
    const colours = getColorsAuto(class_labels.length).map(rgb => styleRgb(rgb))

    createElement(class_labels.length)

    // the number of bars shown. 
    // when there's many items we can truncate and only show the top ones
    let top_n = class_labels.length;
    const height = 60 * top_n;
    let barPadding = (height - (margin.bottom + margin.top)) / (top_n * 5);

    const tickDuration = getTickDuration(interval, min_date, max_date)

    //console.log({ tickDuration })




    // the initial value for the race
    let totals = class_labels.map((l, i) => {
        return {
            name: l,
            value: 0,
            date: min_date,
            lastValue: 0,
            colour: colours[i]
        }
    })

    totals = updateTotals([], cur_date, totals, class_labels)


    let x = d3.scaleLinear()
        .domain([0, Math.max(d3.max(totals, d => d.value), 50)])
        .range([margin.left, width - margin.right - 65]);

    let y = d3.scaleLinear()
        .domain([top_n, 0])
        .range([height - margin.bottom, margin.top]);

    let xAxis = d3.axisTop()
        .scale(x)
        .ticks(width > 500 ? 5 : 2)
        .tickSize(-(height - margin.top - margin.bottom))
        .tickFormat(d => d3.format(',')(d));

    // reusable function for the bar with
    // to stop it going to zero (it's always at least 1)
    const barWidth = (d) => Math.max(x(d.value) - x(0) - 1, 1)

    // the labels don't fit on small bars if there are others
    // which are much bigger. So we move the label outside in that case

    let cur


    const labelx = (d) => {
        let max_val = d3.max(totals, d => d.value)
        // if this item's value is less than 0.2 of the biggest,
        // or if they are all zero
        if (max_val = 0 || d.value / max_val < 0.2) {
            x(d.value) + 100
        } else {
            x(d.value) - 8
        }
    }

    svg.append('g')
        .attr('class', 'axis xAxis')
        .attr('transform', `translate(0, ${margin.top})`)
        .call(xAxis)
        .selectAll('.tick line')
        .classed('origin', d => d == 0);

    svg.selectAll('rect.bar')
        .data(totals, d => d.name)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', x(0) + 1)
        // it was saying neg not allowed, which this would be when there are no detections?
        //.attr('width', d => x(d.value) - x(0) - 1)
        .attr('width', barWidth)
        .attr('y', d => y(d.rank) + 5)
        .attr('height', y(1) - y(0) - barPadding)
        .style('fill', d => d.colour);

    svg.selectAll('text.label')
        .data(totals, d => d.name)
        .enter()
        .append('text')
        .attr('class', 'label')
        .attr('x', labelx)
        .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1)
        .style('text-anchor', 'end')
        .html(d => myMapLabel(d.name));

    svg.selectAll('text.valueLabel')
        .data(totals, d => d.name)
        .enter()
        .append('text')
        .attr('class', 'valueLabel')
        .attr('x', d => x(d.value) + 5)
        .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1)
        .text(d => d3.format(',.0f')(d.lastValue));

    // displays the current date in the corner
    let yearText = svg.append('text')
        .attr('class', 'yearText')
        .attr('x', width - margin.right)
        .attr('y', height - 25)
        .style('text-anchor', 'end')
        .html(formatDate(cur_date, true, false))
        .call(halo, 10);

    let ticker = d3.interval(e => {
        //console.log(cur_date)
        totals = updateTotals(data, cur_date, totals, class_labels)

        //console.log(totals)

        //console.log('IntervalYear: ', yearSlice);

        // adjust the horizonal scale
        x.domain([0, Math.max(d3.max(totals, d => d.value), 50)]);

        svg.select('.xAxis')
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .call(xAxis);

        // apply the data to the svg bars
        let bars = svg.selectAll('.bar').data(totals, d => d.name);

        bars
            .enter()
            .append('rect')
            .attr('class', d => `bar ${d.name.replace(/\s/g, '_')}`)
            .attr('x', x(0) + 1)
            //.attr('width', d => x(d.value) - x(0) - 1)
            .attr('width', d => Math.max(x(d.value) - x(0) - 1, 1))
            //.attr('y', d => y(top_n + 1) + 5)
            .attr('y', () => y(top_n + 1) + 5)
            .attr('height', y(1) - y(0) - barPadding)
            .style('fill', d => d.colour)
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('y', d => y(d.rank) + 5);

        bars
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            //.attr('width', d => x(d.value) - x(0) - 1)
            .attr('width', d => Math.max(x(d.value) - x(0) - 1, 1))
            .attr('y', d => y(d.rank) + 5);

        bars
            .exit()
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            //.attr('width', d => x(d.value) - x(0) - 1)
            .attr('width', d => Math.max(x(d.value) - x(0) - 1, 1))
            //.attr('y', d => y(top_n + 1) + 5)
            .attr('y', () => y(top_n + 1) + 5)
            .remove();

        let labels = svg.selectAll('.label')
            .data(totals, d => d.name);

        labels
            .enter()
            .append('text')
            .attr('class', 'label')
            .attr('x', d => x(d.value) - 8)
            //.attr('y', d => y(top_n + 1) + 5 + ((y(1) - y(0)) / 2))
            .attr('y', () => y(top_n + 1) + 5 + ((y(1) - y(0)) / 2))
            .style('text-anchor', 'end')
            .html(d => d.name)
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1);


        labels
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('x', d => x(d.value) - 8)
            .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1);

        labels
            .exit()
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('x', d => x(d.value) - 8)
            //.attr('y', d => y(top_n + 1) + 5)
            .attr('y', () => y(top_n + 1) + 5)
            .remove();



        let valueLabels = svg.selectAll('.valueLabel').data(totals, d => d.name);

        valueLabels
            .enter()
            .append('text')
            .attr('class', 'valueLabel')
            .attr('x', d => x(d.value) + 5)
            //.attr('y', d => y(top_n + 1) + 5)
            .attr('y', () => y(top_n + 1) + 5)
            .text(d => d3.format(',.0f')(d.lastValue))
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1);

        valueLabels
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('x', d => x(d.value) + 5)
            .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1)
            .tween("text", function (d) {
                let i = d3.interpolateRound(d.lastValue, d.value);
                return function (t) {
                    this.textContent = d3.format(',')(i(t));
                };
            });


        valueLabels
            .exit()
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('x', d => x(d.value) + 5)
            //.attr('y', d => y(top_n + 1) + 5)
            .attr('y', () => y(top_n + 1) + 5)
            .remove();

        yearText
            .transition()
            .duration(tickDuration)
            .ease(d3.easeLinear)
            .tween("html", (d) => {
                let i = d3.interpolate(0, 1);
                return function (t) {
                    const new_date = addIntervalFraction(i(t), interval, cur_date)
                    this.textContent = formatDate(new_date, true, false);
                };
            });

        // //**** text labels  */
        // let textLabels = svg.selectAll('text.label').data(totals, d => d.name);

        // textLabels
        //     .enter()
        //     .append('text')
        //     .attr('class', 'label')
        //     .attr('x', labelx)
        //     .attr('y', () => y(top_n + 1) + 5)
        //     .html(d => myMapLabel(d.name))
        //     .transition()
        //     .duration(tickDuration)
        //     .ease(d3.easeLinear)
        //     .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1);

        // textLabels
        //     .transition()
        //     .duration(tickDuration)
        //     .ease(d3.easeLinear)
        //     .attr('x', labelx)
        //     .attr('y', d => y(d.rank) + 5 + ((y(1) - y(0)) / 2) + 1);


        // textLabels
        //     .exit()
        //     .transition()
        //     .duration(tickDuration)
        //     .ease(d3.easeLinear)
        //     .attr('x', labelx)
        //     .attr('y', () => y(top_n + 1) + 5)
        //     .remove();





        if (cur_date >= max_date) ticker.stop();

        cur_date = addToDate(cur_date, interval)

    }, tickDuration);

}

/**
 * takes a date string or object, adds a number of hours and returns a formatted date string
 * @param {*} date 
 * @param {*} hours 
 * @returns 
 */
const addToDate = (date, interval) => {
    let d = new Date(date);
    //let d = date.getDate();

    if (interval == 'year') {
        d = d.setFullYear(d.getFullYear() + 1)
    } else if (interval == 'month') {
        d = addMonths(d, 1)
    } else if (interval == 'week') {
        d = d.setDate(d.getDate() + 7)
    } else if (interval == 'day') {
        d = d.setDate(d.getDate() + 1)
    } else if (interval == 'hour') {
        d = d.setHour(d.getHour() + 1)
    } else {
        throw 'Invalid interval';
    }

    return new Date(d)
}

/**
 * months is tricky because it sometimes acts weird when adding to the last days of the month
 * probably not applicable to womf but doesn't hurt to address it
 */
const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}


// adds the fraction of the interval to the cur_date 
// and returns a formatted date string
const addIntervalFraction = (fraction, interval, cur_date) => {
    //console.log('addIntervalFraction', fraction, interval, cur_date)
    let d1 = new Date(cur_date);
    let d2 = new Date(addToDate(d1, interval))
    d1 = d1.getTime()
    d2 = d2.getTime()
    let diff = d2 - d1
    let offset = fraction * diff
    let d3 = d1 + offset
    //console.log(new Date(d3))
    return d3
}



/**
 * formats the date string or object the correct way
 * @param {string or date} d 
 * @returns 
 */
const formatDate = (d, human = false, hours = false) => {

    d = new Date(d)
    let new_date_string = ''
    // month is zero indexed, unlike date and hours 
    const yyyy = d.getFullYear()
    let mm = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()]

    if (!human) {
        let mm = (d.getMonth() + 1).toString().padStart(2, '0')
    }

    const dd = d.getDate().toString().padStart(2, '0')
    const hr = hours ? ` ${d.getHours().toString().padStart(2, '0')}:00:00` : ''


    if (human) {
        new_date_string = `${dd} ${mm} ${yyyy}${hr}`
    } else {
        new_date_string = `${yyyy}-${mm}-${dd}${hr}`
    }
    return new_date_string
}

const halo = function (text, strokeWidth) {
    text.select(function () { return this.parentNode.insertBefore(this.cloneNode(true), this); })
        .style('fill', '#ffffff')
        .style('stroke', '#ffffff')
        .style('stroke-width', strokeWidth)
        .style('stroke-linejoin', 'round')
        .style('opacity', 1);

}



export default barchartRace

// export default function loadBarchartRace(url, interval) {

//     fetch(url)
//         .then(response => response.json())
//         .then(data => {
//             createElement();
//             //let formatted_data = preprocessData(data)
//             barchartRace(data, interval);
//         })
//         .catch(error => {
//             console.error('There has been a problem with your fetch operation:', error);
//         });

//     // the timeseries chart we chose uses an old version of d3, but newer ones would be with promise
//     //d3.json(url).then(chart);

// }