
<script setup>
import { onMounted, watch } from 'vue'
// //import { getUser, setUser } from '@/assets/js/auth.js'
// import { useStore } from 'vuex'
// import { useRoute } from 'vue-router'

// const route = useRoute()
// const store = useStore()

// const user = computed(() => store.getters.user)

const props = defineProps(['pins'])
const emit = defineEmits(['pinClicked'])

let map_layer = null;
let map = null;
let infobox = null;

watch(() => props.pins, (new_val, old_val) => {
  console.log('watch pins', new_val, old_val)
  addPins()
})

const pushpinClicked = (dep_id) => {
  emit('pinClicked', dep_id)
  console.log('pushpin clicked for ', dep_id)
}



const addPins = () => {

  console.log('addPins', map, props.pins)

  if (map == null) return;
  if (!Array.isArray(props.pins) || props.pins.length < 1) return;

  console.log('props.pins', props.pins)

  const pushpins = props.pins.map(pin => {
    return new Microsoft.Maps.Pushpin(
      new Microsoft.Maps.Location(pin.latitude, pin.longitude),
      // https://docs.microsoft.com/en-us/bingmaps/v8-web-control/map-control-api/pushpinoptions-object
      { enableHoverStyle: true }
    )
  })

  pushpins.forEach((pp, i) => {
    // we return the index in the deployment list. Maybe more robust to use the deployment id instead?
    Microsoft.Maps.Events.addHandler(pp, 'click', (args) => { 

      infobox.setOptions({
          location: args.target.getLocation(),
          title: props.pins[i].name,
          description: '<span class="birds">birds</span><span class="bats">bats</span>',
//           actions: [{ label: 'See Detections', eventHandler: function () { emit('pinClicked', i)} }],
          visible: true
      });


      document.querySelector('.infobox-info .birds').addEventListener("click", () => emit('pinClicked', i, 0));
      document.querySelector('.infobox-info .bats').addEventListener("click", () => emit('pinClicked', i, 1));
      console.log('EL', document.querySelector('.infobox-body'))

      //pushpinClicked(i) 
      
      });
  })

  map_layer.clear();
  map_layer.add(pushpins);

  const min_lat = props.pins.reduce((cur, prev) => cur.latitude < prev.latitude ? cur : prev).latitude
  const min_long = props.pins.reduce((cur, prev) => cur.longitude < prev.longitude ? cur : prev).longitude
  const max_lat = props.pins.reduce((cur, prev) => cur.latitude > prev.latitude ? cur : prev).latitude
  const max_long = props.pins.reduce((cur, prev) => cur.longitude > prev.longitude ? cur : prev).longitude

  
  const center = new Microsoft.Maps.Location((min_lat + max_lat) / 2, (min_long + max_long) / 2)
  const lat_angle = (max_lat - min_lat) * 1.1
  const long_angle = (max_long - min_long) * 1.1
  // what fits
  const rect = new Microsoft.Maps.LocationRect(center, long_angle, lat_angle)
  console.log('rect', center, long_angle, lat_angle)
  map.setView({bounds: rect})

  // the maximum they can pan/zoom
  const max_rect = new Microsoft.Maps.LocationRect(center, long_angle * 1.4, lat_angle * 1.4)
  map.setOptions({ maxBounds: max_rect });
  



}


const initMap = () => {

    //https://www.bing.com/api/maps/sdk/mapcontrol/isdk/addmultiplelayeritems#JS
    // https://www.bing.com/api/maps/sdk/mapcontrol/isdk/pushpinallevents#JS
    console.log('init map function reached')
    let initial_location = new Microsoft.Maps.Location(-28.1827295, 148.7907944)
    map = new Microsoft.Maps.Map(document.getElementById('myMap'), {
      center: initial_location,
      zoom: 5,
      mapTypeId: Microsoft.Maps.MapTypeId.aerial,
      navigationBarMode: Microsoft.Maps.NavigationBarMode.compact,
      // set a maximum area they can pan/zoom. For no particular reason
      // this will be overwritten by the area enclosing the pins later
      maxBounds: new Microsoft.Maps.LocationRect(initial_location, 30, 22)


    });
    map_layer = new Microsoft.Maps.Layer();
    map.layers.insert(map_layer);
    // single infobox shared between pins, initally hidden
    infobox = new Microsoft.Maps.Infobox(initial_location, { visible: false, autoAlignment: true });
    infobox.setMap(map);

    

    // adding pins on init
    addPins()


}

onMounted(() => {

  // Add a global function for the callback from Bing Maps api
  window.OnLoadBingMapsApi = () => initMap();

  const bing_maps_api_key = process.env.VUE_APP_BING_MAPS_API_KEY

  // attach the bing maps script tag to the dom
  const scriptTag = document.createElement("script");
  scriptTag.src = `https://www.bing.com/api/maps/mapcontrol?key=${bing_maps_api_key}&callback=OnLoadBingMapsApi`;
  scriptTag.id = "scriptBingMaps";
  document.head.appendChild(scriptTag);

})


</script>

<template>

<div id='myMap' style='width: 100%; height: 300px;'></div>

</template>


<style lang='scss'>

@import '@/assets/scss/variables.scss';

#myMap .MicrosoftMap .Infobox .infobox-info span {
  color: $color4;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  &:hover {
    text-decoration: underline;
    color: #000;
  }




}

</style>