<script setup>
//import { computed } from 'vue'
//import { useRoute } from 'vue-router'
//import { getUser } from '@/assets/js/auth.js'
// import LogIn from '@/components/LogIn.vue'
// import { useStore } from 'vuex'
import NavItems from '@/components/NavItems.vue'
import { onBeforeMount, onMounted, onUnmounted } from 'vue'

// can't easily overwrite bootstrap styles. 
// this seems like a hack. 
onBeforeMount(() => {
  document.body.classList.add('home');
})

onMounted(() => {
  document.body.classList.add('home');
  setTimeout(() => document.querySelector('.banner').classList.remove('hidden'), 100);
  
})



onUnmounted(() => {
  document.body.classList.remove('home');
})


// const store = useStore()


//const user = computed(() => store.getUser())

</script>




<template>

<div class="view_bg">

</div>

<div class="view_wrapper home_page">

  <header>
    <div>
    <img src="/img/logo_wide.png" class="logo" />

    <nav-items />
    </div>
    
  </header>

  

  <div class="banner hidden">

  



  </div>


  <div id="content_wrapper">
    <div id="content">

    <!-- <p class="serif">The CRDC has teamed up with researchers from QUT to bring automated biodiversity monitoring to cotton farms.</p> -->
    
    <p class="serif">
    Australian cotton farmers manage large areas of land including native vegetation which provides 
    habitat for biodiversity and contributes to ecosystem services such as natural pest suppression 
    by insect-eating bird and bat species. </p>
    
    
    <p>
    The <b>What’s on my farm</b> website enables growers, the community and industry to monitor bird 
    and bat species and generate biodiversity measures for sustainability reporting.
    
    It is the 
    culmination of a project funded by the <a href="http://www.nrm.gov.au/">National Landcare Program</a> 
    – <a href="https://www.communitygrants.gov.au/grants/national-landcare-program-smart-farming-partnerships-round-2">Smart Farming Partnerships program</a>; 
    a collaboration between the Cotton Research and Development Corporation, University 
    of New England, and Queensland University of Technology.
    
    </p>
    <p>
    
    <b>What’s on my farm</b> shows the results 
    from surveys conducted with acoustic recorders combined with automated artificial intelligence-based 
    systems to detect and identify a suite of bird and bat species.

    </p>

  </div> </div>


  <!-- <template v-if="!store.getters.user">
     <div class="home">Welcome! Log in to find out what's on your farm</div>
     <LogIn />
  
  </template> -->

 
</div>
</template>



<style lang="scss">

@import '@/assets/scss/variables.scss';

$header_height: 270;

.view_wrapper {
  position: relative;
}

.banner {
  position: absolute;
  background-color: #000;
  background-image: url('@/assets/img/field01.jpg');
  background-size: cover;
  min-height: calc(100% - ($header_height * 1px) - 1px);
  height: calc(100vh - ($header_height * 1px) - 1px);
  width: 100%;
  background-position: top center;
  z-index: -1000;
  opacity: 1;
  transition: opacity 5s ease, filter 5s ease;
}

.banner.hidden {
  opacity: 0;
  filter: blur(30px);
}

.home_page {

header {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  .logo {
    height: 150px;
    margin: 20px 0px 20px 0px;
  }
  min-height: $header_height * 1px;
}

#content_wrapper {
  
  display: flex;
  justify-content: center;

  font-size: 1.1em;
  

  p:first-child {
    font-size: 1.6em;
  }

  .get_in_touch {
    font-size: 1.2em;
  }

  padding-top: 80px;
  padding-bottom: 50px;
}

#content {
  border: 10px solid rgba(red($color5l), green($color5l), blue($color5l), 0.9);
  max-width: 850px;
  // transform: translateY(-($banner_height - 80px));
  background: rgba(250, 250, 250, 0.93);
  background-clip: padding-box;
  padding: 50px;
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.2);
  margin-left: 10px;
  margin-right: 10px;
  text-shadow: #fff 0px 0px 10px;
  
}

nav {
  background: $color4;
  text-align: center;
  padding-top:5px;
  padding-bottom:15px;
  text-align: center;
  font-size: 1.5em;

}

nav li {
  list-style: none;
}

li {
  display: inline;
  &::after {
    content: " | "
  }
  &:last-child::after {
    content: ""
  }
}

}

@media (max-width: 600px) {
  #content {
    width: 90vw;
  }
}
@media (min-width: 600px) {
  #content {
    width: 70vw;
  }
}

b {
  color: $color4d;
  font-weight: bold;
}


</style>
