import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChartView from '../views/ChartView.vue'
import LoginView from '../views/LoginView.vue'
// import { useStore } from 'vuex'
// const store = useStore()

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/detections',
    name: 'detections_landing',
    title: "Detections - What's on my farm?",
    heading: "Species",
    component: ChartView,
    beforeEnter: () => {
      // if (store && !store.getters.user) {
      //   return false
      // }
      // return true;
    }
  },
  {
    path: '/detections/:deployment_id/:recognizer_id/:interval',
    name: 'detections',
    title: "Detections - What's on my farm?",
    heading: "Species Detections - What's on my farm?",
    component: ChartView
  },
  {
    path: '/species/birds',
    name: 'bird_species_info',
    title: "Bird Species Information - What's on my farm?",
    heading: "Bird Species Info",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BirdsView.vue')
  }
  ,
  {
    path: '/species/bats',
    name: 'bat_species_info',
    title: "Bat Species Information - What's on my farm?",
    heading: "Bat Species Info",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BatsView.vue')
  }
]

const router_arg = {
  history: createWebHistory(),
  //history: createWebHistory(process.env.BASE_URL),
  routes
}

const router = createRouter(router_arg)

export default router
